import React from "react";

import LogView from "./LogView";
import SystemInfo from "./SystemInfo";
import MrBeamInfo from "./MrBeamInfo";
import Graph from "./Graph";
import Usage from "./Usage";

export default function BundleView(props) {
    return (
        <>
            {props.bundle.systeminfo ? <SystemInfo info={props.bundle.systeminfo}/> : (null)}
            {props.bundle.mrbeaminfo ?
                <MrBeamInfo info={props.bundle.mrbeaminfo} beamos_version={props.bundle.beamos_version} versions={props.bundle.versions} sticks={props.bundle.sticks}/> : (null)}
            {props.bundle.old_data_log?.map((log, index) => (
                <Graph key={"data_log-" + index} iobeam={log.content} data_log={undefined} expanded={true} name={log.log} octoprint_events={props.bundle.octoprint_events} error_events={props.bundle.error_events} device_events={props.bundle.device_events}/>
            ))}
            {props.bundle.data_log?.map((log, index) => (
                <Graph key={"data_log-" + index} iobeam={undefined} data_log={log.content} expanded={true} name={log.log} octoprint_events={props.bundle.octoprint_events} error_events={props.bundle.error_events} device_events={props.bundle.device_events}/>
            ))}
            {props.bundle.usage ? <Usage usage={props.bundle.usage}/> : (null)}
            {props.bundle.logs?.map((log, index) => (
                <LogView key={"log-" + index} expanded={!props.bundle.systeminfo && index === 0} log={log.log}
                         content={log.content} index={index}/>
            ))}
        </>
    )
}