import ziputils from "./zip";
import yaml from 'js-yaml';
import pako from "pako";

// const fs = require('fs');
// const zlib = require('zlib');

async function loadBundle(zip) {
    const files = ["logs/octoprint.log", "iobeam.log", "mount_manager.log", "logs/plugin_softwareupdate_console.log", "plugin_pluginmanager_console.log"];

    let systeminfo = false;
    try {
        systeminfo = await ziputils.getFileContents(zip, "systeminfo.txt", "string");
    } catch(error) {
        console.log("Could not read systeminfo.txt from zip, probably not a bundle...");
    }
    let mrbeaminfo = false;
    try {
        mrbeaminfo = await ziputils.getFileContents(zip, "config/mrbeam.txt", "string");
    } catch(error) {
        console.log("Could not read mrbeam from zip, probably not a bundle...");
    }
    let beamos_version = false;
    try {
        beamos_version = await ziputils.getFileContents(zip, "versions/beamos_version.txt", "string");
    } catch(error) {
        console.log("Could not read beamos_version from zip, probably not a bundle...");
        try {
            beamos_version = await ziputils.getFileContents(zip, "versions/octopi_version.txt", "string");
        } catch(error) {
            console.log("Could not read octopi_version from zip, probably not a bundle...");
        }
    }

    const old_data_log_files = {};
    const old_data_log = [];
    try {
        for (let f of zip.file(/.*\/?iobeam\.log/)) {
            try {
                console.log(`Reading ${f.name} from zip...`);
                if (f.name.endsWith(".gz")) {
                    continue;
                    // const unzipped_iobeam = zip.file(f.name)
                    // console.log(`Unzipped ${unzipped_iobeam.name} from zip... ${unzipped_iobeam}`);
                    // console.log(`Unzipping ${unzipped_iobeam.name} from zip...`);
                    // let iobeam_gz_content = await unzipped_iobeam.async("string");
                    // console.log(`Unzipped ${unzipped_iobeam.name} from zip... ${iobeam_gz_content}`);
                    // var parsed = pako.ungzip(iobeam_gz_content,{ 'to': 'string' });

                    // const unzipped_iobeam = zip.file(f.name)
                    // console.log(`unzipped ${unzipped_iobeam.name}`);
                    // old_data_log_files[unzipped_iobeam.name] = await unzipped_iobeam.async("string");

                    // const compressedData = Buffer.from(unzipped_iobeam, 'base64');
                    // old_data_log_files[unzipped_iobeam.name] = zlib.gunzipSync(compressedData).toString();
                    // old_data_log_files[unzipped_iobeam.name] = (await ungzip(unzipped_iobeam)).toString();
                    // console.log(`Unzipped ${f.name} from zip... ${parsed}`);
                    // old_data_log_files[f.name] = parsed;

                    // const outputFilePath = 'decompressed_file.txt';
                    // const readStream = fs.createReadStream(f.name);
                    //
                    // // Create a writable stream for the decompressed data
                    // const writeStream = fs.createWriteStream(outputFilePath);
                    //
                    // // Pipe the compressed data to the decompression stream
                    // readStream.pipe(zlib.createGunzip()).pipe(writeStream);
                    //
                    // // Event handler for when the decompression is complete
                    // writeStream.on('finish', () => {
                    //   console.log('Decompression complete.');
                    // });
                    // console.log(`Unzipped ${f.name} from zip... ${writeStream}`);
                    // old_data_log_files[f.name] = writeStream;
                } else {
                    old_data_log_files[f.name] = await f.async("string");
                }
            } catch (error) {
                console.log(`Could not read ${f.name} from zip...`);
            }
        }
        for (const filename of files) {
            if (old_data_log_files[filename]) {
                old_data_log.push({ log: filename, content: old_data_log_files[filename] });
            }
        }
        console.log(`old_data_log_files: ${old_data_log_files}`);
        for (const filename of Object.keys(old_data_log_files).sort()) {
            if (!files.includes(filename)) {
                old_data_log.push({ log: filename, content: old_data_log_files[filename] });
            }
        }
    } catch(error) {
        console.log("Could not read iobeam log from zip, probably not a bundle...");
    }
    const data_log_files = {};
    const data_log = [];
    try {
        for (let f of zip.file(/.*\/?data.v\d+\.csv/)) {
            try {
                console.log(`Reading ${f.name} from zip...`);

                data_log_files[f.name] = await f.async("string");

            } catch(error) {
                console.log(`Could not read ${f.name} from zip...`);
            }
        }
        for (const filename of files) {
            if (data_log_files[filename]) {
                data_log.push({ log: filename, content: data_log_files[filename] });
            }
        }
        console.log(`data_log_files: ${data_log_files}`);
        for (const filename of Object.keys(data_log_files).sort()) {
            if (!files.includes(filename)) {
                data_log.push({ log: filename, content: data_log_files[filename] });
            }
        }
    } catch(error) {
        console.log("Could not read iobeam log from zip, probably not a bundle...");
    }

    let usage = false;
    try {
        usage = yaml.load(await ziputils.getFileContents(zip, "analytics/usage.yaml", "string"));
    } catch(error) {
        console.log("Could not read iobeam log from zip, probably not a bundle...");
    }

    const contents = {};
    let octoprint_events = [];
    let error_events = [];
    let device_events = [];
    let versions = [];
    let sticks = [];
    let version_before= ""
    for (const f of zip.file(/\.(log|txt|gcode|gco|g|yaml|json)(\.\d{4}-\d{2}-\d{2})?$/i)) {
        if (f.name.startsWith(".") || f.name.startsWith("__") || f.name === "systeminfo.txt") continue;
        if (f.name.startsWith(".") || f.name.startsWith("__") || f.name === "mrbeam.txt") continue;
        if (f.name.startsWith(".") || f.name.startsWith("__") || f.name === "beamos_version.txt") continue;
        if (f.name.startsWith(".") || f.name.startsWith("__") || f.name === "octopi_version.txt") continue;
        try {
            contents[f.name] = await f.async("string");
            if (f.name.includes("mount_manager")){
                const stick_regex = /^(\d{4}.\d{2}.\d{2} \d{2}:\d{2}:\d{2}).* ##   Stick name:    (\w*)[\s\S]*?Version:\s+(\d+(?:\.\d+)*)[\s\S]*?Build date:\s+(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/gm;
                // const pattern_signal = /^(\d{4}.\d{2}.\d{2} \d{2}:\d{2}:\d{2}).*mrbeam_usb_mount_start> Signaling .*: (\w*)/gm;
                let stick_match;
                while ((stick_match = stick_regex.exec(contents[f.name])) !== null) {
                    sticks.push({timestamp: stick_match[1], stick:stick_match[2], version:stick_match[3], build_date:stick_match[4]});
                }
            }
            if (f.name.includes("octoprint")){
                const pattern = /^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2},\d{3}) - octoprint\.plugin\.core - INFO -.*(.*\n)*\|  Mr Beam Laser Cutter \(([^)]+)\)/gm;
                let version_match;

                while ((version_match = pattern.exec(contents[f.name])) !== null) {
                    let version = version_match[3];
                    let time = version_match[1];
                    if (version !== version_before){
                        version_before = version
                        versions.push({ timestamp: time, version: version });
                    }
                }

                const regex_job_events = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*(on_event (LaserCoolingPause|HighTemperatureWarningDismissed|HighTemperatureWarning|PrintStarted|PrintPaused|PrintResumed|PrintDone|LaserJobAbort|LaserJobCancelled|LaserJobFailed):)|reset exhaust/gm;
                const regex_devices = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*(on_event (iobeam.fan.connected|iobeam.fan.disconnected))|reset exhaust/gm;
                const error_regex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*(on_event (HighTemperatureCriticalShow|HardwareMalfunction):(\s+{'msg':.*'data':\s+{.*u?'code':\s+u?'([^']+)')*)/gm;
                let match;
                while ((match = regex_job_events.exec(contents[f.name])) !== null) {
                    let event = match[3];
                    if (match[5]){
                        console.log(`match: ${match}`, match, match[3], match[4]);
                        event = match[3] + " " + match[5];
                    }
                    octoprint_events.push({ timestamp: match[1], event: event });
                }

                // get errors
                let error_match;
                while ((error_match = error_regex.exec(contents[f.name])) !== null) {
                    let event = error_match[3];
                    if (error_match[5]){
                        console.log(`match: ${error_match}`, error_match, error_match[3], error_match[4]);
                        event = error_match[3] + " " + error_match[5];
                    }
                    error_events.push({ timestamp: error_match[1], event: event });
                }

                let device_event_match;
                while ((device_event_match = regex_devices.exec(contents[f.name])) !== null) {
                    let event = device_event_match[3];
                    if (device_event_match[5]){
                        console.log(`match: ${device_event_match}`, device_event_match, device_event_match[3], device_event_match[4]);
                        event = device_event_match[3] + " " + device_event_match[5];
                    }
                    device_events.push({ timestamp: device_event_match[1], event: event });
                }

                // #get cooling temperature "temperature_max: <value>"

            }
        } catch(error) {
            console.log(`Could not read {f.name} from zip...`);
        }
    }

    //sort the versions by timestamp
    versions.sort((a, b) => {
        return new Date(a.timestamp.replace(' ', 'T').replace(',', '.')) - new Date(b.timestamp.replace(' ', 'T').replace(',', '.'));
    });


    const logs = [];
    for (const filename of files) {
        if (contents[filename]) {
            logs.push({ log: filename, content: contents[filename] });
        }
    }
    for (const filename of Object.keys(contents).sort()) {
        if (!files.includes(filename)) {
            logs.push({ log: filename, content: contents[filename] });
        }
    }

    return {
        hasContent: true,
        systeminfo: systeminfo,
        mrbeaminfo: mrbeaminfo,
        old_data_log: old_data_log,
        data_log: data_log,
        usage: usage,
        beamos_version: beamos_version,
        logs: logs,
        octoprint_events: octoprint_events,
        error_events: error_events,
        device_events: device_events,
        versions: versions,
        sticks: sticks,
    };
}

async function loadLog(blob) {
    return {
        hasContent: true,
        logs: [
            { log: blob.name || "unknown", content: await blob.text() }
        ]
    }
}


const defaultBundle = {
    hasContent: false,
    systeminfo: "",
    mrbeaminfo: "",
    beamos_version: "",
    old_data_log: [],
    data_log: [],
    usage: "",
    logs: [],
    octoprint_events: [],
    error_events: [],
    device_events: [],
    versions: [],
    sticks: [],
};

const utils = {
    loadBundle: loadBundle,
    loadLog: loadLog,
    defaultBundle: defaultBundle
}

export default utils;
