import React, {} from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from "@mui/material/Typography";
import Plot from 'react-plotly.js';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    accordionbar: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        [theme.breakpoints.down('lg')]: {
            flexWrap: "wrap",
        },
    },
    grow: {
        flexGrow: 1,
    },
    icon: {
        padding: theme.spacing(0, 1),
        fontSize: "1.5rem",
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        padding: theme.spacing(0, 1),
        textAlign: "right",
        [theme.breakpoints.down('lg')]: {
            textAlign: "left",
            padding: theme.spacing(0, 0),
        },
    },
    title: {
        flexGrow: 1,
        alignItems: "center",
        [theme.breakpoints.down('lg')]: {
            flexBasis: "100%"
        }
    },
    info: {
        [theme.breakpoints.down('lg')]: {
            flexBasis: "100%"
        }
    }
}));

export default function Graph(props) {
    // const [data, setData] = useState(null);
    const data_input = props.iobeam;//.split("\n");
    const data_log = props.data_log; //Data from csv file
    const oprint_events = props.octoprint_events;
    const error_events = props.error_events;
    const device_events = props.device_events;
    const name = props.name;

    // console.log(data_input);
    const classes = useStyles();
    const regex = /(?<time>\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2},\d{3}).*?('dust':\s(?<dust>[\d\.]+).*?)?'rpm':\s(?<rpm>[\d\.]+).*?('dust':\s(?<pressure>[\d\.]+).*)?'temp':\s'?(?<temp>[\d\.]+)'?.*('current':\s?(?<current>[\d\.]+))?/gm;
    let match;
    let times = [];
    let dustLevels = [];
    let rpms = [];
    let pressures1 = [];
    let pressures2 = [];
    let pressures3 = [];
    let pressures4 = [];
    let pressPrefilter = [];
    let pressMainfilter = [];
    let temperatures = [];
    let currents = [];
    let filterPercentage = [];
    let firstDate = null;
    let lastDate = null;
    if (data_input) {
        while ((match = regex.exec(data_input)) !== null) {
            let group = match.groups;
            times.push(new Date(group['time'].replace(' ', 'T').replace(',', '.')));
            dustLevels.push(parseFloat(group['dust']));
            rpms.push(parseFloat(group['rpm']));
            pressures1.push(parseFloat(group['pressure']));
            temperatures.push(parseFloat(group['temp']));
            currents.push(parseFloat(group['current']));
        }
    } else if (data_log) {
        const data = data_log.replace(/{}/g, '-1');
        const results = [];
        const lines = data.split('\n');
        const headers = lines[0].split(',');
        let version = 1;

        const version_match = name.match(/\.*?data.v(\d+)\.csv/);
        if (version_match) {
          version = parseInt(version_match[1]);
          console.log("version "+version); // This will print "42" in this example
        }
        for (let i = 1; i < lines.length; i++) {
            const currentLine = lines[i].split(',');

            if (currentLine.length === headers.length) {
                const entry = {};

                for (let j = 0; j < headers.length; j++) {
                    entry[headers[j]] = currentLine[j];
                }

                results.push(entry);
            }
        }
        //Timestamp,Uptime,Exhaust Pressure,Exhaust Ext Voltage,Exhaust RPM,Exhaust Dust,Temperature,Compressor Pressure,Compressor PWM,Compressor RPM,Compressor Voltage,Compressor Mode,PCF Input
        times = results.map(function (entry) {
            return new Date(entry['Timestamp'].replace(' ', 'T').replace(',', '.'))
        });
        dustLevels = results.map(function (entry) {
            return parseFloat(entry['Exhaust Dust'])
        });
        rpms = results.map(function (entry) {
            return parseFloat(entry['Exhaust RPM'])
        });
        if (version === 1) {
            pressures1 = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure'])
            });
        }else if(version === 2){
            pressures1 = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 1'])
            });
            pressures2 = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 2'])
            });
            pressures3 = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 3'])
            });
            pressures4 = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 4'])
            });
            pressPrefilter = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 2'])-parseFloat(entry['Exhaust Pressure 3'])
            });
            pressMainfilter = results.map(function (entry) {
                return parseFloat(entry['Exhaust Pressure 3'])-parseFloat(entry['Exhaust Pressure 4'])
            });
        }
        temperatures = results.map(function (entry) {
            return parseFloat(entry['Temperature'])
        });
    }

    firstDate = times[0];
    lastDate = times[times.length - 1];


    let range_breaks = [];
    let shapes = [];
    for (let i = 0; i < times.length - 1; i++) {
      const currentTimestamp = new Date(times[i]);
      const nextTimestamp = new Date(times[i + 1]);

      // Calculate the time difference in milliseconds
      const timeDifference = nextTimestamp - currentTimestamp;

      if (timeDifference > 60 * 60 * 1000) { // Check if the gap is > 1 hour (3600000 milliseconds)
          const startTimestamp = new Date(currentTimestamp);
          const endTimestamp = new Date(nextTimestamp);
          startTimestamp.setHours(startTimestamp.getHours()+2)
          endTimestamp.setHours(endTimestamp.getHours()-2)

          const element = {
            pattern: 'day',
            bounds: [startTimestamp, endTimestamp],
        }
        range_breaks.push(element);
        temperatures[i] = -1;
        temperatures[i+1] = -1;

        const maxYValue = Math.max(...temperatures)//, dustLevels, pressures);
        const minYValue = Math.min(...temperatures)//, dustLevels, pressures);

        shapes.push( {
          type: 'rect',
          x0: currentTimestamp, // Start time of the grey background
          x1: nextTimestamp, // End time of the grey background
          y0: minYValue, // Y-axis starting point
          y1: maxYValue, // Y-axis ending point
          fillcolor: 'grey',
          opacity: 0.3, // Adjust the opacity as needed
          line: {
            width: 0,
          },
        });

      }
    }


    //percentage_filter
    filterPercentage = rpms.map(function (entry, index) {
        const FACTOR = 0.35*100;
        const fan_value = parseFloat(entry);//rpm 0-10000

        let dust_sum = 0;
        let rpm_sum = 0;
        let pressure_sum = 0;
        let dust_count  = 0;
        let rpm_count = 0;
        let pressures_count = 0;
        let amount = 100;

        for (let i = index - 1; i >= index - amount && i >= 0; i--) {
            dust_sum += dustLevels[i];
            dust_count++;
        }
        let average_dust = dust_sum / dust_count;
        for (let i = index - 1; i >= index - amount && i >= 0; i--) {
            rpm_sum += rpms[i];
            rpm_count++;
        }
        let average_rpm = rpm_sum / rpm_count;
        entry = average_rpm;
        for (let i = index - 1; i >= index - amount && i >= 0; i--) {
            pressure_sum += pressures1[i];
            pressures_count++;
        }
        //TODO use all the avg
        let average_pressure = pressure_sum / pressures_count;


        const fanSetting = FACTOR / average_dust;//100;// pwm vorgabe 0-100
        // console.log("fanSetting", fanSetting, average_dust);
        const compPress = parseFloat(average_pressure); //pressure 0-1000
        let percentage = -1
        if (fan_value !== -1 && compPress !== -1) {

            const newFilter = -6.90 * fanSetting + 1163;   //Filter neu
            const oldFilter = -10.35 * fanSetting + 1242;  //Filter dreckig
            const newFilterRPM = 4096.2482 * Math.log(fanSetting / 100.0) + 7994.3403;
            //log
            const oldFilterRPM = 9647.325 * Math.pow(fanSetting / 100.0, 0.7125);
            let percentFilterRPM = (fan_value - newFilterRPM) / (oldFilterRPM - newFilterRPM);
            // console.log("percentFilterRPM", fan_value, "-", newFilterRPM, "-", oldFilterRPM, "-", percentFilterRPM);
            if (percentFilterRPM < 0) percentFilterRPM = 0;
            if (percentFilterRPM > 1) percentFilterRPM = 1;
            let percentFilterPress = (newFilter - compPress) / (newFilter - oldFilter);
            // console.log("percentFilterPress", newFilter, "-", compPress, "-", oldFilter, "-", percentFilterPress);
            if (percentFilterPress < 0) percentFilterPress = 0;
            if (percentFilterPress > 1) percentFilterPress = 1;
            if (compPress === -1) {
                percentFilterPress = percentFilterRPM;
            }
            // (percentFilterRPM + percentFilterPress) / 2) * 100)
            // if (fan_value > 0) {
            //     console.log(fan_value, "-", fanSetting, "-", percentFilterRPM, "-", percentFilterPress);
            // }
            percentage = ((percentFilterRPM + percentFilterPress) / 2) * 100;
            //  if ((mainfilterPercent < ((float)(percentFilterRPM + percentFilterPress) / 2) * 100) || ((mainfilterPercent) < (((float)mainfilterTime / mainfilterLifetime) * 100))) {
            //   if (mainfilterPercent < 100) {
            //     mainfilterPercent++;
            //     eepromWrite(eepromAdressMainfilterPercent, mainfilterPercent);
            //   }
            // }

            // if (compPress > 930) {
            //     //kein filter vorhanden
            //   } else {
            //     newFilter = -6.90 * fanSetting + 1163;   //Filter neu
            //     oldFilter = -10.35 * fanSetting + 1242;  //Filter dreckig
            //     newFilterRPM = 4096.2482 * log((double)fanSetting / 100) + 7994.3403;
            //     oldFilterRPM = 9647.325 * pow((double)fanSetting / 100, 0.7125);
            //     percentFilterRPM = (exh_con.dyn.fan_value - newFilterRPM) / (oldFilterRPM - newFilterRPM);
            //     if (percentFilterRPM < 0) percentFilterRPM = 0;
            //     if (percentFilterRPM > 1) percentFilterRPM = 1;
            //     if (exh_con.data_1.model > 1) {
            //       percentFilterPress = (newFilter - compPress) / (newFilter - oldFilter);
            //       if (percentFilterPress < 0) percentFilterPress = 0;
            //       if (percentFilterPress > 1) percentFilterPress = 1;
            //     } else percentFilterPress = percentFilterRPM;
            //     if ((mainfilterPercent < ((float)(percentFilterRPM + percentFilterPress) / 2) * 100) || ((mainfilterPercent) < (((float)mainfilterTime / mainfilterLifetime) * 100))) {
            //       if (mainfilterPercent < 100) {
            //         mainfilterPercent++;
            //         eepromWrite(eepromAdressMainfilterPercent, mainfilterPercent);
            //       }
            //     }
            //     if (prefilterPercent < (((float)prefilterTime / prefilterLifetime) * 100)) {
            //       if (prefilterPercent < 100) {
            //         prefilterPercent++;
            //         eepromWrite(eepromAdressPrefilterPercent, prefilterPercent);
            //       }
            //     }

        }
        return percentage;
    });


    let events = {};
    var oprint_events_filtered = oprint_events.filter(function (event) {
        var timestamp = new Date(event.timestamp.replace(' ', 'T').replace(',', '.'));
        if (timestamp >= firstDate && timestamp <= lastDate) {
            return event
        }
    });

    // Add events to graph
    if (oprint_events_filtered.length > 0) {
        events = {
            x: oprint_events_filtered.map(function (entry) {
                return new Date(entry.timestamp.replace(' ', 'T').replace(',', '.'))
            }),
            y: oprint_events_filtered.map(function (entry) {
                return 0
            }),
            mode: 'markers',
            customdata: oprint_events_filtered.map(function (entry) {
                return entry.event
            }),
            name: 'events',
            hovertemplate: '%{x}: %{customdata}',
        };
    }

    let errors = {};
    var error_events_filtered = error_events.filter(function (event) {
        var timestamp = new Date(event.timestamp.replace(' ', 'T').replace(',', '.'));
        if (timestamp >= firstDate && timestamp <= lastDate) {
            return event
        }
    });

    // Add events to graph
    if (error_events_filtered.length > 0) {
        errors = {
            x: error_events_filtered.map(function (entry) {
                return new Date(entry.timestamp.replace(' ', 'T').replace(',', '.'))
            }),
            y: error_events_filtered.map(function (entry) {
                return 0
            }),
            mode: 'markers',
            customdata: error_events_filtered.map(function (entry) {
                return entry.event
            }),
            name: 'errors',
            hovertemplate: '%{x}: %{customdata}',
            marker: {
                color: 'red',
            }
        };
    }

    let device_events_graph = {};
    var device_events_filtered = device_events.filter(function (event) {
        var timestamp = new Date(event.timestamp.replace(' ', 'T').replace(',', '.'));
        if (timestamp >= firstDate && timestamp <= lastDate) {
            return event
        }
    });

    // Add events to graph
    if (device_events_filtered.length > 0) {
        device_events_graph = {
            x: device_events_filtered.map(function (entry) {
                return new Date(entry.timestamp.replace(' ', 'T').replace(',', '.'))
            }),
            y: device_events_filtered.map(function (entry) {
                return 0
            }),
            mode: 'markers',
            customdata: device_events_filtered.map(function (entry) {
                return entry.event
            }),
            name: 'device event',
            hovertemplate: '%{x}: %{customdata}',
        };
    }

    // setData({ temperatures, times });
    const data = [
        {
            x: times,
            y: temperatures,
            type: 'scatter',
            mode: 'lines',
            name: 'Temperature',
            visible: true,
        },
        {
            x: times,
            y: rpms,
            type: 'scatter',
            mode: 'lines',
            name: 'RPM',
            visible: true,
            yaxis: 'y2',
        },
        {
            x: times,
            y: dustLevels,
            type: 'scatter',
            mode: 'lines',
            name: 'Dust',
            visible: true,
        },
        {
            x: times,
            y: filterPercentage,
            type: 'scatter',
            mode: 'lines',
            name: 'BETA Filter percentage',
            visible: true,
            // yaxis: 'y3',
        },
        {
            x: times,
            y: pressures1,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure',
            yaxis: 'y2',
        },
        {
            x: times,
            y: pressures2,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure 2',
            yaxis: 'y2',
        },
        {
            x: times,
            y: pressures3,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure 3',
            yaxis: 'y2',
        },
        {
            x: times,
            y: pressures4,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressure 4',
            yaxis: 'y2',
        },
        {
            x: times,
            y: pressPrefilter,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressdrop Prefilter',
            yaxis: 'y2',
        },
        {
            x: times,
            y: pressMainfilter,
            type: 'scatter',
            mode: 'lines',
            name: 'Pressuredrop Mainfilter',
            yaxis: 'y2',
        },
        events,
        errors,
        device_events_graph
    ]

    const trace = {
        x: times,
        y: temperatures,
        mode: 'lines',
        type: 'scatter',
    };

    const layout = {
        yaxis2: {
            title: 'Pressure (Pa)',
            overlaying: 'y', // Overlay the second x-axis on top of the first x-axis
            side: 'right', // Place the second x-axis on the right side of the plot
            fixedrange: true
        },
        shapes: shapes,
    //[
            // cooling temperature of laserhead

            //Set cooling temperature line
            // {
            //   type: 'line',
            //   x0: firstDate,
            //   x1: lastDate,
            //   y0: 60,
            //   y1: 60,
            //   line: {
            //     color: 'red',
            //     width: 3,
            //     dash: 'dashdot',
            //   },
            // },
        // ],
        xaxis: {
            title: 'Time',
            type: 'date',
            primaryXAxis: {
                valueType: 'DateTime',
            },
            rangebreaks: range_breaks
        },
        yaxis: {
            title: 'Temperature (C)',
            tickformat: '.2f°C',
            fixedrange: true
        },
    };
    const chartDataAvailable = temperatures.length > 0 || rpms.length > 0 || dustLevels.length > 0 || pressures1.length > 0 || dustLevels.length > 0 || filterPercentage.length > 0 || events.length > 0 || errors.length > 0;
    var expanded2 = props.expanded;
    if (!chartDataAvailable) {
        expanded2 = false;
    }
    return (
        <Accordion defaultExpanded={expanded2}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel-sysinfo-content">
                <div className={classes.accordionbar}>
                    <div className={classes.title}>
                        <Typography className={classes.heading} style={{display: "flex", alignItems: "center"}}>
                            Usage {name}
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>

            <AccordionDetails>
                {chartDataAvailable ? (
                <Plot data={data} layout={layout} style={{width: "100%", height: "100%"}} useResizeHandler={true}/>
                ) : (
        <p>No data available for the chart.</p>
      )}
            </AccordionDetails>

        </Accordion>


    );

}
