import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import makeStyles from "@mui/styles/makeStyles";



const useStyles = makeStyles((theme) => ({
    accordionbar: {
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        [theme.breakpoints.down('lg')]: {
            flexWrap: "wrap",
        },
    },
    grow: {
        flexGrow: 1,
    },
    icon: {
        padding: theme.spacing(0, 1),
        fontSize: "1.5rem",
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.secondary,
        padding: theme.spacing(0, 1),
        textAlign: "right",
        [theme.breakpoints.down('lg')]: {
            textAlign: "left",
            padding: theme.spacing(0, 0),
        },
    },
    title: {
        flexGrow: 1,
        alignItems: "center",
        [theme.breakpoints.down('lg')]: {
            flexBasis: "100%"
        }
    },
    info: {
        [theme.breakpoints.down('lg')]: {
            flexBasis: "100%"
        }
    }
}));

function getNestedJobTime(data) {
    const formatSeconds = (seconds) => {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      return `${hours}h ${minutes}m`;
    }

    for (let key in data) {
        if (typeof data[key] === "object") {
            if (data[key]!= null && "job_time" in data[key]) {
                data[key].job_time = formatSeconds(data[key].job_time);
            } else {
                data[key] = getNestedJobTime(data[key]);
            }
        }
    }
    return data;
}

export default function Usage(props) {


    let data = props.usage;
    console.log("usage", data);

    const formatSeconds = (seconds) => {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      return `${hours}h ${minutes}m`;
    }

    data = getNestedJobTime(data);



    const laserheads = Object.entries(data.laser_head);
    let airfilter = [];
    if(data.airfilter) {
        airfilter = Object.entries(data.airfilter);
    }
    const classes = useStyles();

    return (
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-sysinfo-content" id="panel-sysinfo-header">
                <div className={classes.accordionbar}>
                    <div className={classes.title}>
                        <Typography className={classes.heading} style={{ display: "flex", alignItems: "center" }}>
                            Usage
                        </Typography>
                    </div>
                </div>
            </AccordionSummary>

            <AccordionDetails>
                <div>

            { data.gantry ? <div>gantry: {data.gantry.job_time}</div> : <div>no gantry data</div>}
            { data.carbon_filter ? <div>carbon filter: {data.carbon_filter.job_time}</div> : (null)}
            { data.compressor ? <div>compressor: {data.compressor.job_time}</div> : (null)}

            laserheads:<ul> {laserheads?.map(([key, value]) => (
                <li key={key}>
                  <span>{key}: </span>
                  <span>{value.job_time}</span>
                </li>
            ))}</ul>
            airfilter:<ul> {airfilter?.map(([key, value]) => (
                <li key={key}>
                    <span>{key}: </span>
                    <ul>
                        <li>prefilter:
                            <span>{value.prefilter.job_time}</span>
                            { value.prefilter.pressure ? <span> - pressure: {value.prefilter.pressure}</span> : (null)}
                        </li>
                        <li>carbon_filter:
                            <span>{value.carbon_filter.job_time}</span>
                            { value.carbon_filter.pressure ? <span> - pressure: {value.carbon_filter.pressure}</span> : (null)}
                        </li>
                    </ul>
                </li>
            ))}</ul>
            { data.laser_head[0] ? <div><strong>laser_head:</strong> {data.laser_head[0].job_time}</div> : (null)}
            { data.prefilter ? <div>prefilter: {data.prefilter.job_time}</div> : (null)}
            { data.total ? <div>total: {data.total.job_time}</div> : (null)}
            { data.succ_jobs ? <div>jobs: {data.succ_jobs.count}</div> : (null)}

      </div>
            </AccordionDetails>
        </Accordion>
    )
}